import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { get } from "lodash"
import styled from "@emotion/styled"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { colors } from "../../styles/colors"
import Img from "gatsby-image"
import { UnderlinedTextCTAExternal } from "../Partials/TextPieces"
import { fluid } from "../../styles/mixins"
import { breakpoints } from "../../styles/sizes"
import { maxW } from "../../styles/mediaQueries"
import closeButton from "../../images/close-button.svg"
import { css } from "@emotion/core"
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks
} from "body-scroll-lock"

// we're disabling eslint for this line because react scrollmagic does not like working function components
// eslint-disable-next-line no-unused-vars
const Header = React.forwardRef(({ logoImg }, _ref) => {
    const [isClicked, setIsClicked] = useState(false)

    useEffect(() => {
        const modal = document.getElementById("mobile-menu-container")

        if (isClicked) {
            disableBodyScroll(modal)
        } else {
            enableBodyScroll(modal)
        }

        return () => {
            clearAllBodyScrollLocks()
        }
    }, [isClicked])

    const navigationQuery = useStaticQuery(
        graphql`
            query {
                allNavigationJson {
                    nodes {
                        id
                        label
                        path
                    }
                }
            }
        `
    )

    const navigationTabs = get(navigationQuery, "allNavigationJson.nodes", [])

    const handleClick = () => {
        if (!isClicked) {
            setIsClicked(true)
        } else {
            setIsClicked(false)
        }
    }

    return (
        <>
            <HeaderContainer>
                <MemberLogin>
                    <UnderlinedTextCTAExternal href="https://members.lacoquilleclub.com/">
                        MEMBER LOGIN
                    </UnderlinedTextCTAExternal>
                </MemberLogin>
                <LogoAndNavContainer id="logo-and-nav-container">
                    <Link to="/">
                        <LogoContainer>
                            <Img
                                css={css`
                                    ${maxW[1]} {
                                        display: none;
                                    }
                                `}
                                fluid={logoImg}
                                alt={"La Coquille Logo"}
                            />
                        </LogoContainer>
                    </Link>
                    <Nav id="navbar">
                        {navigationTabs.map(nav => {
                            return (
                                <Link
                                    key={nav.id}
                                    to={nav.path}
                                    activeClassName="active"
                                    partiallyActive
                                >
                                    {nav.label}
                                </Link>
                            )
                        })}
                    </Nav>
                </LogoAndNavContainer>
            </HeaderContainer>
            <MobileNav id="mobile-nav">
                <Link to="/">
                    <MobileLogoContainer>
                        <Img fluid={logoImg} alt={"La Coquille logo"} />
                    </MobileLogoContainer>
                </Link>
                <MenuToggle id="menuToggle">
                    <ClickReceiver
                        type="checkbox"
                        onClick={handleClick}
                        className={isClicked ? "disable" : ""}
                    />
                    <MenuText>Menu</MenuText>
                    <MobileMenuContainer
                        id="mobile-menu-container"
                        className={isClicked ? "modal-activated" : ""}
                    >
                        <Menu id="menu" className={isClicked ? "opened" : ""}>
                            {navigationTabs.map(nav => {
                                return (
                                    <Link
                                        key={nav.id}
                                        to={nav.path}
                                        activeClassName="active"
                                        partiallyActive
                                    >
                                        <li onClick={handleClick}>
                                            {nav.label}
                                        </li>
                                    </Link>
                                )
                            })}
                            <MobileMemberLogin>
                                <UnderlinedTextCTAExternal href="https://members.lacoquilleclub.com/">
                                    MEMBER LOGIN
                                </UnderlinedTextCTAExternal>
                            </MobileMemberLogin>
                            <CloseButton
                                src={closeButton}
                                alt={"La Coquille logo"}
                                onClick={handleClick}
                            />
                        </Menu>
                    </MobileMenuContainer>
                </MenuToggle>
            </MobileNav>
        </>
    )
})

export default Header

const HeaderContainer = styled.header`
    padding-top: 30px;
`
const LogoAndNavContainer = styled.div`
    display: flex;
    text-align: center;
    margin: auto;
    flex-direction: column;
    align-items: center;
    position: relative;
`
const LogoContainer = styled.div`
    width: 116px;
    height: 146px;
`
const Nav = styled.nav`
    padding: 40px 0;
    a {
        font-family: ${fontFamily.accent};
        padding-bottom: 40px;
        margin: 0 20px;
        ${fluid("font-size", 14, 18, breakpoints[2], breakpoints[3])}
        color: ${colors.eastBay};
        letter-spacing: 3.56px;
        &.active {
            border-bottom: 5px solid ${colors.eastBay};
            padding-bottom: 35px;
        }
    }
    ${maxW[1]} {
        display:none;
    }
`
const MemberLogin = styled.div`
    position: absolute;
    right: 0;
    margin: 0 100px;
    z-index: 1;
    ${maxW[1]} {
        display: none;
    }
`
const MobileNav = styled.nav`
    display: none;
    ${maxW[1]} {
        display: block;
        height: 100px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1;
        transition: background-color 0.3s;
        &.background-on {
            background-color: ${colors.white};
        }
    }
`
const MenuToggle = styled.div`
    display: block;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    a {
        text-decoration: none;
        color: #232323;
        transition: color 0.3s ease;
    }
    a:hover {
        color: ${colors.eastBay};
    }
    input:checked ~ ul {
        transform: none;
    }
`
const ClickReceiver = styled.input`
    display: block;
    position: absolute;
    width: 56px !important;
    height: 31px !important;
    top: 37px !important;
    right: 18px !important;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
    &.disable {
        pointer-events: none;
    }
`
const Menu = styled.ul`
    width: 80%;
    height: 100vh;
    background: ${colors.white};
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    margin: -170px 0px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    transform: translateX(100%);
    position: absolute;
    right: 0;
    top: 170px;
    a {
        display: inline-table;
    }
    li {
        font-family: ${fontFamily.accent};
        font-size: 14px;
        color: ${colors.eastBay};
        letter-spacing: 2.58px;
        line-height: 2.81;
        text-transform: uppercase;
    }
    &.opened {
        transform: translateX(0%);
    }
`
const MenuText = styled.p`
    font-family: ${fontFamily.accent};
    font-size: 14px;
    color: ${colors.eastBay};
    letter-spacing: 2.58px;
    line-height: 1.81;
    text-transform: uppercase;
    position: absolute;
    top: 40px;
    right: 20px;
`
const MobileMenuContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    &.modal-activated {
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.4);
    }
`
const MobileLogoContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 15px;
    width: 60px;
`
const MobileMemberLogin = styled.div`
    position: absolute;
    right: -20px;
    top: 280px;
    width: 100%;
`
const CloseButton = styled.img`
    width: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`
